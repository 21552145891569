import { gql } from "@apollo/client";

export const GET_BRAND_BY_ID = gql`
  query Get_Brand_Name_By_ID($id: uuid!) {
    brand_name_by_pk(id: $id) {
      id
      name
      cover_image_url
      image_url
      about_us_html
      created_at
      updated_at
    }
  }
`;


export const BRANDS = gql`
  query Brand_Name {
    brand_name(order_by: { updated_at: desc }) {
      created_at
      id
      name
      cover_image_url
      image_url
      about_us_html
      updated_at
    }
  }
`;

export const CREATE_BRAND = gql`
  mutation Insert_Brand_Name(
    $name: String!
    $image_url: String!
    $about_us_html: String!
    $cover_image_url: String!
  ) {
    insert_brand_name_one(
      object: {
        name: $name
        image_url: $image_url
        about_us_html: $about_us_html
        cover_image_url: $cover_image_url
      }
    ) {
      id
      name
      created_at
      updated_at
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation Update_Brand_Name(
    $id: uuid!
    $name: String!
    $image_url: String!
    $about_us_html: String!
    $cover_image_url: String!
  ) {
    update_brand_name_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        image_url: $image_url
        about_us_html: $about_us_html
        cover_image_url: $cover_image_url
      }
    ) {
      created_at
      id
      name
      updated_at
    }
  }
`;

export const DELETE_BRAND = gql`
  mutation Delete_Brand_Name($id: uuid!) {
    delete_brand_name_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_POSITION = gql`
  mutation Update_Brand_Position($id: uuid!, $updateAt: timestamptz) {
    update_brand_name_by_pk(
      pk_columns: { id: $id }
      _set: { updated_at: $updateAt }
    ) {
      created_at
      id
      name
      updated_at
    }
  }
`;
