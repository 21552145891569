import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { BRANDS, GET_BRAND_BY_ID, UPDATE_BRAND } from "../../gql/brands";

import {
  Breadcrumbs,
  Box,
  Card,
  CardContent,
  FormControl,
  TextField,
  Typography,
  Button,
  CardMedia,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DELETE_IMAGE, GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
import imageService from "../../services/image";
import RichTextEditor from "react-rte";
import { Link, useNavigate, useParams } from "react-router-dom";
const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];
const UpdateBrand = ({ handleClose, brandAlert}) => {
  
  const {id} = useParams(); 
  const {data} = useQuery(GET_BRAND_BY_ID, {
    variables: {id},
  });
  console.log(data?.brand_name_by_pk);
  
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ name: "" });
  const [errors, setErrors] = useState({ name: "" });

  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState(null);

  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [coverImageFile, setCoverImageFile] = useState(null);
  const [coverImageFileUrl, setCoverImageFileUrl] = useState(null);
  const [isCoverImageChange, setIsCoverImageChange] = useState(false);
  const [oldCoverImageName, setOldCoverImageName] = useState(null);

  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const nav = useNavigate();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [deleteImage] = useMutation(DELETE_IMAGE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
  });

  const [deleteCoverImage] = useMutation(DELETE_IMAGE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
  });

  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsImageChange(true);
      setValues({
        ...values,
        image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });
  const [getCoverImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: (result) => {
      setCoverImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsCoverImageChange(true);
      setValues({
        ...values,
        cover_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          product_image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          product_image_url: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl({ contentType: "image/*" });
    }
  };

  const coverImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          product_image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          product_image_url: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setCoverImageFile(img);
      setCoverImagePreview(URL.createObjectURL(img));
      getCoverImageUrl({ contentType: "image/*" });
    }
  };

  const [updateBrand] = useMutation(UPDATE_BRAND, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
    onCompleted: () => {
      // setValues({ name: "" });
      // setErrors({ name: "" });
      setLoading(false);
      nav("/brands")
      // brandAlert("Brand have been updated");
      // handleClose();
    },
  });

  useEffect(() => {
    if (data?.brand_name_by_pk) {
      setValues({
        name: data?.brand_name_by_pk?.name,
        image_url: data?.brand_name_by_pk?.image_url,
        cover_image_url: data?.brand_name_by_pk?.cover_image_url,
        about_us_html: data?.brand_name_by_pk?.about_us_html,
      });

      setTextValue(
        RichTextEditor.createValueFromString(data?.brand_name_by_pk?.about_us_html, "html")
      );
      setImagePreview(data?.brand_name_by_pk?.image_url);
      let image_urls = data?.brand_name_by_pk?.image_url;
      setOldImageName(
        image_urls?.substring(
          image_urls.lastIndexOf("/") + 1,
          image_urls.lenght
        )
      );
      setCoverImagePreview(data?.brand_name_by_pk?.cover_image_url);
      let image_url = data?.brand_name_by_pk?.cover_image_url;
      setOldCoverImageName(
        image_url?.substring(image_url.lastIndexOf("/") + 1, image_url.lenght)
      );
    }
  }, [data?.brand_name_by_pk]);

  const onChange = (value) => {
    setTextValue(value);
    setValues({ ...values, about_us_html: value.toString("html") });
  };

  const handleUpdate = async () => {
    setLoading(true);
    setErrors({ name: "" });
    let isErrorExit = false;
    let errorObject = {};
    if (!values.name) {
      errorObject.name = "Name field is required.";
      isErrorExit = true;
    }
    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }
    try {
      if (isImageChange) {
        await imageService.uploadImage(imageFileUrl, imageFile);
        await deleteImage({ variables: { image_name: oldImageName } });
      }
      if (isCoverImageChange) {
        await imageService.uploadImage(coverImageFileUrl, coverImageFile);
        await deleteCoverImage({
          variables: { image_name: oldCoverImageName },
        });
      }
      await updateBrand({ variables: { ...values, id } });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
       <Breadcrumbs aria-label="breadcrumb">
          <Link to="/">Dashboard</Link>
          <Link to="/brand">UpdateBrand</Link>
        </Breadcrumbs>

        <Button onClick={handleClose} variant="outlined" sx={{ height: 50 }}>
          Close
        </Button>
      </Box>
      <Card sx={{ display: "flex", justifyContent: "space-between" }}>
        <CardContent sx={{ flex: 3 }}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "column",
                flex: 3,
                width: "100%",
                minHeight: "300px",
                my: 2,
              }}
            >
              <CardMedia
                component="img"
                image={imagePreview}
                alt="Brand"
                sx={{
                  bgcolor: "#cecece",
                  height: "300px",
                  objectFit: "contain",
                  borderRadius: "10px",
                  padding: 1,
                }}
              />
              <Typography
                variant="span"
                component="div"
                sx={{ textAlign: "center", color: "blue" }}
              >
                Image
              </Typography>
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                flexDirection: "column",
                flex: 3,
                width: "100%",
                minHeight: "300px",
                my: 2,
              }}
            >
              <CardMedia
                component="img"
                image={coverImagePreview}
                alt="Brand"
                sx={{
                  bgcolor: "#cecece",
                  height: "300px",
                  objectFit: "contain",
                  borderRadius: "10px",
                  padding: 1,
                }}
              />
              <Typography
                variant="span"
                component="div"
                sx={{ textAlign: "center", color: "blue" }}
              >
                Cover Image
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl sx={{ m: 2 }}>
              <TextField
                id="image"
                placeholder="Upload image"
                InputLabelProps={{ shrink: true }}
                label="Upload Image"
                onChange={imageChange}
                error={errors.image_url ? true : false}
                helperText={errors.image_url}
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              />
            </FormControl>
            <FormControl sx={{ m: 2 }}>
              <TextField
                id="image"
                placeholder="Upload Cover Image"
                InputLabelProps={{ shrink: true }}
                label="Upload Image"
                onChange={coverImageChange}
                error={errors.image_url ? true : false}
                helperText={errors.image_url}
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              />
            </FormControl>
            <FormControl sx={{ m: 2 }} variant="outlined">
              <TextField
                id="name"
                label="Name"
                value={values.name}
                onChange={handleChange("name")}
                error={errors.name ? true : false}
                helperText={errors.name}
              />
            </FormControl>
            <Box sx={{ gridColumn: "1/-1" }}>
              <InputLabel>Description</InputLabel>
              <RichTextEditor
                style={{ height: "100px", overflow: "scroll" }}
                value={textValue}
                onChange={onChange}
              />
              {errors.about_us_html && (
                <FormHelperText error>{errors.about_us_html}</FormHelperText>
              )}
            </Box>
            <FormControl sx={{ m: 2 }} variant="outlined">
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={handleUpdate}
                sx={{ backgroundColor: "#4b26d1", alignSelf: "end" }}
              >
                Update
              </LoadingButton>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default UpdateBrand;
